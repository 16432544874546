import React from "react";
import { Root, Routes } from "react-static";
//
import "./app.css";
//
export default () => (
  <Root>
    <Routes />
  </Root>
);
